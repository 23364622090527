import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 解决路由跳转报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// 针对 push 方法
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {path: '/', name: 'white', redirect:'/white'},
  {path: '/white', name: 'white', component: () => import('../views/common/white.vue'),meta:{title: '加载中'}},
  {path: '/login', name: 'login', component: () => import('../views/common/login.vue'),meta:{title: '登录'}},
  {path: '/normally', name: 'normally', component: () => import('../views/common/normally.vue'),meta:{title: '加载中'}},
  {path: '/sxcitybrainSSO', name: 'sxcitybrainSSO', component: () => import('../views/common/sxcitybrainSSO.vue'),meta:{title: '登录'}},
  {path: '/home', name: 'Home', component: () => import('../views/home/Home.vue'),meta:{title: '首页'}},
  {path: '/work', name: 'work', component: () => import('../views/work/work.vue'),meta:{title: '工作台'}},
  {path: '/statistics', name: 'statistics', component: () => import('../views/statistics/statistics.vue'),meta: {title: "数据统计"}},
  {path: '/my', name: 'my', component: () => import('../views/my/my.vue'),meta: {title: "我的"} },
  {path: '/my-info', name: 'my-info', component: () => import('../views/my/my-info.vue') ,meta: {title: "我的信息"}},
  {path: '/my-info-change', name: 'my-info-change', component: () => import('../views/my/my-info-change.vue') ,meta: {title: "信息修改"}},
  {path: '/my-qrcode', name: 'my-qrcode', component: () => import('../views/my/my-qrcode.vue') ,meta: {title: "我的二维码"}},
  {path: '/pwd-change', name: 'pwd-change', component: () => import('../views/my/pwd-change.vue') ,meta: {title: "修改密码"}},
  {path: '/mobile-change', name: 'mobile-change', component: () => import('../views/my/mobile-change.vue') ,meta: {title: "更换手机号"}},
  {path: '/notice', name: 'notice', component: () => import('../views/notice/notice.vue'), meta:{title: '公告'} },
  {path: '/notice-info', name: 'notice-info', component: () => import('../views/notice/notice-info.vue'), meta:{title: '公告详情'} },
  {path: '/notice-update', name: 'notice-update', component: () => import('../views/notice/notice-update.vue'),meta:{title:"公告详情"}},
  {path: '/guide', name: 'guide', component: () => import('../views/guide/guide.vue'), meta:{title: '办事指南'} },
  {path: '/guide-info', name: 'guide-info', component: () => import('../views/guide/guide-info.vue'), meta:{title: '指南详情'} },
  {path: '/consultInfo', name: 'consultInfo', component: () => import('../views/guide/consultInfo.vue'), meta:{title: '咨询回复'} },
  {path: '/problem', name: 'problem', component: () => import('../views/problem/problem.vue'), meta:{title: '问题'} },
  {path: '/problem-info', name: 'problem-info', component: () => import('../views/problem/problem-info.vue'), meta:{title: '问题详情'} },
  {path: '/problem-add', name: 'problem-add', component: () => import('../views/problem/problem-add.vue'), meta:{title: '新增问题'}},
  {path: '/problem-four', name: 'problem-four', component: () => import('../views/problem/problem-four.vue'), meta:{title: '新增问题'}},
  {path: '/problem-finish', name: 'problem-finish', component: () => import('../views/problem/problem-finish.vue'), meta:{title: '新增问题'}},
  {path: '/problem-verify', name: 'problem-verify', component: () => import('../views/problem/problem-verify.vue'), meta:{title: '新增问题'}},
  {path: '/problem-handling', name: 'problem-handling', component: () => import('../views/problem/problem-handling.vue'), meta:{title: '处理问题'}},
  {path: '/problem-change', name: 'problem-change', component: () => import('../views/problem/problem-change.vue'), meta:{title: '修改问题'}},
  {path: '/login', name: 'login', component: () => import('../views/common/login.vue') },
  {path: '/community-change', name: 'community-change', component: () => import('../views/common/community-change.vue') },
  {path: '/nation', name: 'nation', component: () => import('../views/nation/nation.vue'), meta:{title: '民族', keepAlive: true} },
  {path: '/nationality', name: 'nationality', component: () => import('../views/nation/nationality.vue'), meta:{title: '国家', keepAlive: true} },
  {path: '/censor', name: 'censor', component: () => import('../views/censor/censor.vue'), meta:{title: '检查', keepAlive: true} },
  {path: '/censor-add', name: 'censor-add', component: () => import('../views/censor/censor-add.vue'), meta:{title: '新增检查', isRefresh: 1,keepAlive: true} },
  {path: '/patrol', name: 'patrol', component: () => import('../views/patrol/patrol.vue'), meta:{title: '巡查'} },
  {path: '/patrol-add', name: 'patrol-add', component: () => import('../views/patrol/patrol-add.vue'), meta:{title: '新增巡查', isRefresh: 1,keepAlive: true} },
  {path: '/repair', name: 'repair', component: () => import('../views/repair/repair.vue'), meta:{title: '报修'} },
  {path: '/repair-add', name: 'repair-add', component: () => import('../views/repair/repair-add.vue'), meta:{title: '新增报修'} },
  {path: '/repair-info', name: 'repair-info', component: () => import('../views/repair/repair-info.vue'), meta:{title: '报修详情'} },
  {path: '/repair-change', name: 'repair-change', component: () => import('../views/repair/repair-change.vue'), meta:{title: '提交报修'} },
  {path: '/repair-handling', name: 'repair-handling', component: () => import('../views/repair/repair-handling.vue'), meta:{title: '报修处理'} },
  {path: '/visit', name: 'visit', component: () => import('../views/visit/visit.vue'), meta: {title: '走访', keepAlive: true}},
  {path: '/survey', name: 'survey', component: () => import('../views/visit/survey.vue') },
  {path: '/code', name: 'code', component: () => import('../views/visit/code.vue') },
  // {path: '/visit-add', name: 'visit-add', component: () => import('../views/visit/visit-add.vue'), meta: {title: '走访', keepAlive: false}},
  {path: '/visit-add', name: 'visit-add', component: () => import('../views/visit/visit-add1.vue'), meta: {title: '走访', isRefresh: 1,keepAlive: true}},
  {path: '/resList', name: 'resList', component: () => import('../views/visit/resList.vue') },
  {path: '/tabulation', name: 'tabulation', component: () => import('../views/questionnaire/tabulation.vue'), meta:{title: '问卷'} },
  {path: '/questionnaire', name: 'questionnaire', component: () => import('../views/questionnaire/questionnaire.vue'), meta:{title: '问卷'} },
  {path: '/questionInfo', name: 'questionInfo', component: () => import('../views/questionnaire/questionInfo.vue'), meta:{title: '问卷'} },
  {path: '/answer-info', name: 'answer-info', component: () => import('../views/questionnaire/answer-info.vue'), meta:{title: '问卷'} },
  {path: '/questionList', name: 'questionList', component: () => import('../views/questionnaire/questionList.vue'), meta:{title: '问卷'} },
  {path: '/questionWrite', name: 'questionWrite', component: () => import('../views/questionnaire/questionWrite.vue'), meta:{title: '问卷'} },
  {path: '/communityCode', name: 'communityCode', component: () => import('../views/communityCode/communityCode.vue'), meta:{title: '社区码'} },
  {path: '/communityCode-list', name: 'communityCode-list', component: () => import('../views/communityCode/communityCode-list.vue'), meta:{title: '社区码'} },
  {path: '/place', name: 'place', component: () => import('../views/place/place.vue'), meta:{title: '场所管理', keepAlive: true}  },
  {path: '/place-add', name: 'place-add', component: () => import('../views/place/place-add.vue'), meta:{title: '场所新增'}  },
  {path: '/place-info', name: 'place-info', component: () => import('../views/place/place-info.vue'), meta:{title: '场所详情'}  },
  {path: '/device', name: 'device', component: () => import('../views/device/device.vue'), meta:{title: '设备管理', keepAlive: true} },
  {path: '/device-info', name: 'device-info', component: () => import('../views/device/device-info.vue'), meta:{title: '设备详情'} },
  {path: '/device-check-info', name: 'device-check-info', component: () => import('../views/device/device-check-info.vue'), meta:{title: '设备管理'} },

  {path: '/branch', name: 'branch', component: () => import('../views/party/list.vue'), meta:{title: '社区党建'} },
  {path: '/party-personnel-search', name: 'partySearch', component: () => import('../views/party/search.vue'),meta:{title:"社区党建"}},
  {path: '/party-personnel-info', name: 'partyInfo', component: () => import('../views/party/info.vue'),meta:{title:"社区党建"}},
  // {path: '/branch', name: 'branch', component: () => import('../views/party/branch.vue'), meta:{title: '社区党建'} },
  {path: '/branch-info', name: 'branch-info', component: () => import('../views/party/branch-info.vue'), meta:{title: '社区党建'} },
  {path: '/party-info', name: 'party-info', component: () => import('../views/party/party-info.vue'), meta:{title: '社区党建'} },
  {path: '/party-info-add', name: 'party-info-add', component: () => import('../views/party/party-info-add.vue'), meta:{title: '社区党建'} },
  {path: '/activity', name: 'activity', component: () => import('../views/activity/activity.vue'), meta:{title: '活动', keepAlive: true} },
  {path: '/activity-add', name: 'activity-add', component: () => import('../views/activity/activity-add.vue'), meta:{title: '新增活动'} },
  {path: '/activity-info', name: 'activity-info', component: () => import('../views/activity/activity-info.vue'), meta:{title: '活动详情'} },
  {path: '/activityCode', name: 'activityCode', component: () => import('../views/activity/code.vue') },
  {path: '/activitySuccess', name: 'activitySuccess', component: () => import('../views/activity/success.vue'), meta:{title: '活动签到'} },
  {path: '/actives-apply-info', name: 'activesApplyInfo', component: () => import('../views/activity/apply-info.vue'),meta:{title:"活动签到"}},
  {path: '/actives-people-add', name: 'activesPeopleAdd', component: () => import('../views/activity/people-add.vue'),meta:{title:"活动签到"}},
  {path: '/house', name: 'house', component: () => import('../views/house/house.vue'), meta:{title: '房屋管理', keepAlive: true}},
  {path: '/house-view-details', name: 'house-view-details', component: () => import('../views/house/view-details.vue'), meta:{title: '房屋详情', keepAlive: true}},
  {path: '/house-info', name: 'house-info', component: () => import('../views/house/house-info.vue'), meta:{title: '房屋详情',  isRefresh: 1, fromRefresh: 0, keepAlive: false} },
  {path: '/house-add', name: 'house-add', component: () => import('../views/house/house-add.vue'), meta:{title: '新增房屋',  keepAlive: false} },
  {path: '/house-move', name: 'house-move', component: () => import('../views/house/house-move.vue'), meta:{title: '更多'} },
  {path: '/agreement', name: 'agreement', component: () => import('../views/common/agreement.vue') },
  {path: '/mission', name: 'mission', component: () => import('../views/guProject/mission.vue') },
  {path: '/mission-info', name: 'mission-info', component: () => import('../views/guProject/mission-info.vue') },
  {path: '/tasks', name: 'tasks', component: () => import('../views/guProject/tasks.vue')},
  {path: '/guHome', name: 'guHome', component: () => import('../views/guProject/guHome.vue') },
  {path: '/sso', name: 'sso', component: () => import('../views/common/sso.vue')},
  {path: '/fourSSO', name: 'fourSSO', component: () => import('../views/common/fourSSO.vue')},
  {path: '/wisdom', name: 'wisdom', component: () => import('../views/buildStrong/wisdom.vue'), meta:{title: '建强党建'} },
  {path: '/wisdom-info', name: 'wisdom-info', component: () => import('../views/buildStrong/wisdom-info.vue'), meta:{title: '建强党建'}},
  {path: '/trifle', name: 'trifle', component: () => import('../views/buildStrong/trifle.vue'), meta:{title: '民生小事'}},
  {path: '/trifle-info', name: 'trifle-info', component: () => import('../views/buildStrong/trifle-info.vue'), meta:{title: '小事详情'}},
  {path: '/trifle-add', name: 'trifle-add', component: () => import('../views/buildStrong/trifle-add.vue'), meta:{title: '新增小事'}},
  {path: '/trifle-handling', name: 'trifle-handling', component: () => import('../views/buildStrong/trifle-handling.vue'), meta:{title: '处理小事'}},
  {path: '/trifle-change', name: 'trifle-change', component: () => import('../views/buildStrong/trifle-change.vue'), meta:{title: '修改小事'}},
  {path: '/issues', name: 'issues', component: () => import('../views/buildStrong/issues/issues.vue'), meta:{title: '民生小事'}},
  {path: '/issues-info', name: 'issues-info', component: () => import('../views/buildStrong/issues/issues-info.vue'), meta:{title: '小事详情'}},
  {path: '/issues-add', name: 'issues-add', component: () => import('../views/buildStrong/issues/issues-add.vue'), meta:{title: '新增小事'}},
  {path: '/issues-handling', name: 'issues-handling', component: () => import('../views/buildStrong/issues/issues-handling.vue'), meta:{title: '处理小事'}},
  {path: '/issues-change', name: 'issues-change', component: () => import('../views/buildStrong/issues/issues-change.vue'), meta:{title: '修改小事'}},
  {path: '/userRes-index', name: 'userResIndex', component: () => import('../views/userRes/index.vue'), meta:{title: '居民管理', keepAlive: true} },
  {path: '/userRes-info', name: 'userResInfo', component: () => import('../views/userRes/info.vue'), meta:{title: '居民信息', keepAlive: false, isRefresh: 0}  },
  {path: '/userRes-dataCheck', name: 'userResDataCheck', component: () => import('../views/userRes/dataCheck.vue'), meta:{title: '居民数据核查'}  },
  {path: '/userHouse-info', name: 'userHouseInfo', component: () => import('../views/userRes/userHouse-info.vue'), meta:{title: '居民房屋信息', keepAlive: false, isRefresh: 0}  },
  {path: '/relation-add', name: 'relation-add', component: () => import('../views/userRes/relation-add.vue'), meta:{title: '关系详情'}  },
  {path: '/petition-info', name: 'petitionInfo', component: () => import('../views/userRes/petition-info.vue'), meta:{title: '上访信息'}  },
  {path: '/cause-info', name: 'causeInfo', component: () => import('../views/userRes/cause-info.vue'), meta:{title: '肇事信息'}  },
  {path: '/resCar-add', name: 'resCar-add', component: () => import('../views/userRes/resCar-add.vue'), meta:{title: '居民车辆信息'}  },
  {path: '/file', name: 'file', component: () => import('../views/file/file.vue'), meta:{title: '文档管理'}},
  {path: '/file-info', name: 'file-info', component: () => import('../views/file/file-info.vue'), meta:{title: '文档管理'}},
  {path: '/file-pdf', name: 'file-pdf', component: () => import('../views/file/pdf.vue'), meta:{title: '文档管理'}},
  {path: '/check', name: 'check', component: () => import('../views/check/check.vue'), meta:{title: '导出审核'}},
  {path: '/letter', name: 'letter', component: () => import('../views/letter/letter.vue'), meta:{title: '人大信箱'}},
  {path: '/letter-add', name: 'letterAdd', component: () => import('../views/letter/letter-add.vue'), meta:{title: '人大信箱'}},
  {path: '/insured', name: 'insured', component: () => import('../views/insured/insured.vue'), meta:{title: '安全责任险管理'}},
  {path: '/insured-add', name: 'insuredAdd', component: () => import('../views/insured/insured-add.vue'), meta:{title: '安全责任险管理'}},
    //AI
  // {path: '/session', name: 'session', component: () => import('../views/ai-file/session.vue'), meta:{title: '文件检索'}},
 //农牧
  {path: '/farm-index', name: 'FarmIndex', component: () => import('../views/Farm/farm-index.vue'), meta:{title: '农牧信息', keepAlive: true} },
  {path: '/farm-info', name: 'FarmInfo', component: () => import('../views/Farm/farm-info.vue'), meta:{title: '农牧新增'} },
  //设备
  {path: '/shebei-index', name: 'shebeiIndex', component: () => import('../views/Farm/shebei/shebei-index.vue'), meta:{title: '设备信息', keepAlive: true} },
  {path: '/shebei-info', name: 'shebeiInfo', component: () => import('../views/Farm/shebei/shebei-info.vue'), meta:{title: '设备新增'} },
    //养殖
  {path: '/zoon-index', name: 'zoonIndex', component: () => import('../views/Farm/zoon/zoon-index.vue'), meta:{title: '养殖信息', keepAlive: true} },
  {path: '/zoon-info', name: 'zoonInfo', component: () => import('../views/Farm/zoon/zoon-info.vue'), meta:{title: '养殖新增'} },
    //种植
  {path: '/plant-index', name: 'plantIndex', component: () => import('../views/Farm/plant/plant-index.vue'), meta:{title: '种植信息', keepAlive: true} },
  {path: '/plant-info', name: 'plantInfo', component: () => import('../views/Farm/plant/plant-info.vue'), meta:{title: '种植新增'} },
  //生育登记
  {path: '/pregnancy', name: 'pregnancy', component: () => import('../views/pregnancy/pregnancy.vue'),meta:{title:"生育登记"}, },
  {path: '/pregnancy-add', name: 'pregnancy-add', component: () => import('../views/pregnancy/pregnancy-add.vue'),meta:{title:"生育登记详情"}, },
  {path: '/agedService', name: 'agedService', component: () => import('../views/agedService/agedService.vue'),meta:{title:"生育登记详情"}, },


  {path: '/acyeterionList', name: 'acyeterionList', component: () => import('../views/acyeterion/acyeterionList'), meta:{title:"避孕药具发放"}},
  {path: '/acyeterionList-add', name: 'acyeterionList-add', component: () => import('../views/acyeterion/acyeterionList-add'), meta:{title:"避孕药具发放"}},
  {path: '/ffsms', name: 'ffsms', component: () => import('../views/ffsms/ffsms'), meta:{title:"服务经费"}},
  {path: '/ffsms-add', name: 'ffsms-add', component: () => import('../views/ffsms/ffsms-add'), meta:{title:"服务经费"}},


  {path: '/propaganda', name: 'propaganda', component: () => import('../views/propaganda/propaganda.vue'), meta:{title:"宣传录用"}},
  {path: '/propaganda-add', name: 'propaganda-add', component: () => import('../views/propaganda/propaganda-add.vue'), meta:{title:"宣传录用"}},
  {path: '/mini', name: 'mini', component: () => import('../views/mini/mini.vue'), meta:{title:"微型消防站"}},
  {path: '/mini-add', name: 'mini-add', component: () => import('../views/mini/mini-add.vue'), meta:{title:"微型消防站"}},
  {path: '/mini-info', name: 'mini-info', component: () => import('../views/mini/mini-info.vue'), meta:{title:"微型消防站"}},

  {path: '/live-old', name: 'live-old',  component: () => import('../views/live-old/live-old.vue'), meta:{title:"居家养老"}},
  {path: '/live-detail', name: 'live-old-detail',  component: () => import('../views/live-old/live-old-detail.vue'), meta:{title:"居家养老"}},
  {path: '/rescue-list', name: 'rescue-list',  component: () => import('../views/rescue-station/list.vue'), meta:{title:"社会救助站"}},
  {path: '/rescue-detail', name: 'rescue-detail',  component: () => import('../views/rescue-station/detail.vue'), meta:{title:"求助详情"}},
  {path: '/rescue-evaluate', name: 'rescue-evaluate',  component: () => import('../views/rescue-station/evaluate.vue'), meta:{title:"查看评价"}},
  {path: '/rescue-result', name: 'rescue-result',  component: () => import('../views/rescue-station/result.vue'), meta:{title:"上传救助结果"}},

  { path: '/gridWorkGoals', name: 'gridWorkGoals', component: () => import('../views/workGoals/workGoals.vue'), meta:{title:"工作目标"}},
  { path: '/gridRecentWork', name: 'gridRecentWork', component: () => import('../views/workGoals/recentWork.vue') },
  { path: '/gridRecentWorkInfo', name: 'gridRecentWorkInfo', component: () => import('../views/workGoals/recentWorkInfo.vue') },
  { path: '/gridAddRecentWork', name: 'gridAddRecentWork', component: () => import('../views/workGoals/addRecentWork.vue') },

  { path: '/gridGroupTogeter', name: 'gridGroupTogeter', component: () => import('../views/groupTogeter/groupTogeter.vue'), meta:{title:"群团聚力"}},
  { path: '/gridUnion', name: 'gridUnion', component: () => import('../views/groupTogeter/union.vue') },

  { path: '/gridThereServiceOpen', name: 'gridThereServiceOpen', component: () => import('../views/thereServiceOpen/thereServiceOpen.vue'), meta:{title:"三务公开"}},
  { path: '/gridOpenVillage', name: 'gridOpenVillage', component: () => import('../views/thereServiceOpen/openVillage.vue') },
  { path: '/gridOpenLabour', name: 'gridOpenLabour', component: () => import('../views/thereServiceOpen/openLabour.vue') },
  { path: '/gridAddOpenVillage', name: 'gridAddOpenVillage', component: () => import('../views/thereServiceOpen/addOpenVillage.vue') },

    //老人服务
  { path: '/older-service', name: 'older-service', component: () => import('../views/older-service/older-service'), meta:{title:"老人服务"}},

  //服务单详情
  { path: '/request-detail', name: 'request-detail', component: () => import('../views/older-service/request-detail'), meta:{title:"服务单详情"}},


  { path: '/manage-remind', name: 'manage-remind', component: () => import('../views/common/manage-remind') },
  { path: '/common-func', name: 'common-func', component: () => import('../views/common/common-func') },

  {path: '/404', name: '404', component: () => import('../views/common/404.vue') },

  {path: '/formList', name: 'formList', component: () => import('../views/form/list.vue') },
  {path: '/form', name: 'form', component: () => import('../views/form/form.vue') },
  {path: '/myForm', name: 'myForm', component: () => import('../views/form/myForm.vue') },

  {path: '/cityBrain', name: 'cityBrain', component: () => import('../views/cityBrain/index.vue') },
  {path: '/unemploymentList', name: 'unemploymentList', component: () => import('../views/cityBrain/unemploymentList.vue') },
  {path: '/unemploymentInfo', name: 'unemploymentInfo', component: () => import('../views/cityBrain/unemploymentInfo.vue') },
  {path: '/regAddressList', name: 'regAddressList', component: () => import('../views/cityBrain/regAddressList.vue') },
  {path: '/regAddressInfo', name: 'regAddressInfo', component: () => import('../views/cityBrain/regAddressInfo.vue') },
  {path: '/basicAllowanceList', name: 'basicAllowanceList', component: () => import('../views/cityBrain/basicAllowanceList.vue') },
  {path: '/basicAllowanceInfo', name: 'basicAllowanceInfo', component: () => import('../views/cityBrain/basicAllowanceInfo.vue') },
  {path: '/myApp', name: 'myApp', component: () => import('../views/myApp/index.vue') },
  {path: '/appSSO', name: 'appSSO', component: () => import('../views/myApp/appSSO.vue'),meta:{title:"应用中心"} },
  {path: '/normally', name: 'normally', component: () => import('../views/common/normally.vue') },

  //  任务通
  {path: '/task', name: 'task', component: () => import('../views/visitTask/task.vue'), meta:{title:"任务通"}},
  {path: '/taskList', name: 'taskList', component: () => import('../views/visitTask/taskList.vue'), meta:{title:"走访任务"}},
  {path: '/taskInfo', name: 'taskInfo', component: () => import('../views/visitTask/taskInfo.vue'), meta:{title:"走访任务"}},
  {path: '/patrolList', name: 'patrolList', component: () => import('../views/visitTask/patrolList.vue'), meta:{title:"巡查任务"}},
  {path: '/patrolInfo', name: 'patrolInfo', component: () => import('../views/visitTask/patrolInfo.vue'), meta:{title:"巡查任务"}},
  {path: '/activityList', name: 'activityList', component: () => import('../views/visitTask/activityList.vue'), meta:{title:"活动任务"}},
  {path: '/activityInfo', name: 'activityInfo', component: () => import('../views/visitTask/activityInfo.vue'), meta:{title:"活动任务"}},
  {path: '/otherList', name: 'otherList', component: () => import('../views/visitTask/otherList.vue'), meta:{title:"其他任务"}},
  {path: '/otherInfo', name: 'otherInfo', component: () => import('../views/visitTask/otherInfo.vue'), meta:{title:"其他任务"}},
  {path: '/basicAllowanceInfo', name: 'basicAllowanceInfo', component: () => import('../views/cityBrain/basicAllowanceInfo.vue') },
  {path: '/myApp', name: 'myApp', component: () => import('../views/myApp/index.vue') },
  {path: '/appSSO', name: 'appSSO', component: () => import('../views/myApp/appSSO.vue'), meta:{title:"应用中心"}},

    //汽车充电
  {path: '/carCharge', name: 'carCharge', component: () => import('../views/carCharging/carCharge'),meta:{title:"汽充列表"}, },
  {path: '/carCharge-add', name: 'carCharge-add', component: () => import('../views/carCharging/carCharge-add'),meta:{title:"汽充详情"}, },

  {path: '/emergency-index', name: 'emergencyIndex', component: () => import('../views/emergency/index.vue'),meta:{title:"突发事件"}},
  {path: '/emergency-info', name: 'emergencyInfo', component: () => import('../views/emergency/info.vue'),meta:{title:"突发事件"}},
  {path: '/illegalBuilding', name: 'illegalBuilding', component: () => import('../views/illegalBuilding/illegalBuilding.vue'),meta:{title:"违章建筑管理"}},
  {path: '/illegalBuilding-add', name: 'illegalBuilding-add', component: () => import('../views/illegalBuilding/illegalBuilding-add.vue'),meta:{title:"违章建筑管理", isRefresh: 1, keepAlive: true}},
  {path: '/tripleCotasking', name: 'tripleCotasking', component: () => import('../views/tripleCotasking/tripleCotasking.vue'),meta:{title:"三方协同"}},
  {path: '/tripleCotasking-add', name: 'tripleCotasking-add', component: () => import('../views/tripleCotasking/tripleCotasking-add.vue'),meta:{title:"三方协同"}},
  {path: '/dueDate', name: 'dueDate', component: () => import('../views/dueDate/dueDate.vue'),meta:{title:"预产期"}},
  {path: '/dueDate-info', name: 'dueDate-info', component: () => import('../views/dueDate/dueDate-info.vue'),meta:{title:"预产期"}},

  //  出租屋
  {path: '/numWarning', name: 'numWarning', component: () => import('../views/numWarning/numWarning.vue'),meta:{title:"出租屋"}},

  //  车辆管理
  {path: '/car', name: 'car', component: () => import('../views/carManage/car.vue'),meta:{title:"车辆管理"}},
  {path: '/car-add', name: 'car-add', component: () => import('../views/carManage/car-add.vue'),meta:{title:"车辆管理"}},
  {path: '/car-illPark', name: 'car-illPark', component: () => import('../views/carManage/car-illPark.vue'),meta:{title:"车辆违停"}},
  //  疫情隔离
  {path: '/isolate', name: 'isolate', component: () => import('../views/isolate/isolate.vue') ,meta:{title:"疫情隔离"}},
  {path: '/isolate-info', name: 'isolate-info', component: () => import('../views/isolate/isolate-info.vue') ,meta:{title:"疫情隔离"}},
  {path: '/isolate-add', name: 'isolate-add', component: () => import('../views/isolate/isolate-add.vue') ,meta:{title:"疫情隔离"}},

  //  闸门人大
  {path: '/entrance', name: 'entrance', component: () => import('../views/wePeople/entrance.vue')},
  {path: '/sideNotice', name: 'sideNotice', component: () => import('../views/wePeople/sideNotice.vue')},
  {path: '/sideNotice-info', name: 'sideNotice-info', component: () => import('../views/wePeople/sideNotice-info.vue')},
  {path: '/peopleMail', name: 'peopleMail', component: () => import('../views/wePeople/peopleMail.vue')},
  {path: '/peopleMail-info', name: 'peopleMail-info', component: () => import('../views/wePeople/peopleMail-info.vue')},
  //  返程人员
  {path: '/back', name: 'back', component: () => import('../views/backPeople/back.vue') ,meta:{title:"返程人员"}},
  {path: '/back-info', name: 'back-info', component: () => import('../views/backPeople/back-info.vue') ,meta:{title:"返程人员"}},

  //  数据统计
  {path: '/statistics-user', name: 'statistics-user', component: () => import('../views/statistics/modules/user.vue') ,meta:{title:"居民统计"}},
  {path: '/statistics-place', name: 'statistics-place', component: () => import('../views/statistics/modules/place.vue') ,meta:{title:"场所统计"}},
  {path: '/statistics-problem', name: 'statistics-problem', component: () => import('../views/statistics/modules/problem.vue') ,meta:{title:"解决问题数统计"}},
  {path: '/statistics-device', name: 'statistics-device', component: () => import('../views/statistics/modules/device.vue') ,meta:{title:"设备统计"}},
  {path: '/statistics-house', name: 'statistics-house', component: () => import('../views/statistics/modules/house.vue') ,meta:{title:"房屋统计"}},
  {path: '/statistics-visit', name: 'statistics-visit', component: () => import('../views/statistics/modules/visit.vue') ,meta:{title:"走访统计"}},
  {path: '/statistics-patrol', name: 'statistics-patrol', component: () => import('../views/statistics/modules/patrol.vue') ,meta:{title:"巡查统计"}},
  {path: '/statistics-active', name: 'statistics-active', component: () => import('../views/statistics/modules/active.vue') ,meta:{title:"活动统计"}},
  {path: '/statistics-check', name: 'statistics-check', component: () => import('../views/statistics/modules/inspect.vue') ,meta:{title:"检查统计"}},
  {path: '/statistics-maintain', name: 'statistics-maintain', component: () => import('../views/statistics/modules/maintain.vue') ,meta:{title:"维护更新统计"}},

  {path: '/video', name: 'video', component: () => import('../views/video/index.vue') ,meta:{title:"视频监控"}},
  {path: '/message', name: 'message', component: () => import('../views/message/message.vue') ,meta:{title:"短信群发"}},
  {path: '/search-result', name: 'search-result', component: () => import('../views/globalSearch/search-result.vue') ,meta:{title:"搜索结果", keepAlive: true}},
  {path: '/result-info', name: 'result-info', component: () => import('../views/globalSearch/result-info.vue') ,meta:{title:"搜索结果"}},
  {path: '/partyActiveSign', name: 'result-info', component: () => import('../views/common/partyActiveSign.vue') ,meta:{title:"活动签到"}},

  // 沿街店铺管理
  {path: '/streetShop', name: 'streetShop', component: () => import('../views/place/streetShop/place.vue'), meta:{title: '沿街店铺管理', keepAlive: true}  },
  {path: '/streetShop-add', name: 'streetShop-add', component: () => import('../views/place/streetShop/place-add.vue'), meta:{title: '沿街店铺新增'}  },
  {path: '/streetShop-info', name: 'streetShop-info', component: () => import('../views/place/streetShop/place-info.vue'), meta:{title: '沿街店铺详情'}  },

  // 企业管理
  {path: '/business', name: 'business', component: () => import('../views/place/business/place.vue'), meta:{title: '企业管理', keepAlive: true}  },
  {path: '/business-add', name: 'business-add', component: () => import('../views/place/business/place-add.vue'), meta:{title: '企业新增'}  },
  {path: '/business-info', name: 'business-info', component: () => import('../views/place/business/place-info.vue'), meta:{title: '企业详情'}  },

  {path: '/place-list', name: 'place-list', component: () => import('../views/visit/placeList.vue'), meta:{title: '场所列表', fromRefresh: 0}  },
  {path: '/res-list', name: 'res-list', component: () => import('../views/visit/resList.vue'), meta:{title: '居民列表', fromRefresh: 0}  },
  {path: '/worker-list', name: 'worker-list', component: () => import('../views/visit/workerList.vue'), meta:{title: '陪访人列表', fromRefresh: 0}  },
  {path: '/loginCheck', name: 'loginCheck', component: () => import('../views/common/loginCheck.vue'), meta:{title: '居民登录信息审核'}  },

  {path: '/personal-info', name: 'personal-info', component: () => import('../views/personalInfo/index.vue'), meta:{title: '个人信息'}  },
  {path: '/daily-paper', name: 'daily-paper', component: () => import('../views/dailyPaper/index.vue'), meta:{title: '工作日报'}  },
  {path: '/daily-paper-editor', name: 'daily-paper-editor', component: () => import('../views/dailyPaper/editor.vue'), meta:{title: '工作日报'}  },
  {path: '/preferences', name: 'preferences', component: () => import('../views/my/preferences.vue'), meta:{title: '偏好设置'}  },
  // 街道首页
  {path: '/street-home', name: 'street-home', component: () => import('../views/streetHome/Home.vue'),meta:{title: '首页'}},
  // 街道我的
  {path: '/street-my', name: 'street-my', component: () => import('../views/streetMy/my.vue')},
  {path: '/street-my-info', name: 'street-my-info', component: () => import('../views/streetMy/my-info.vue') },
  {path: '/street-my-info-change', name: 'street-my-info-change', component: () => import('../views/streetMy/my-info-change.vue') },
  {path: '/street-my-qrcode', name: 'street-my-qrcode', component: () => import('../views/streetMy/my-qrcode.vue') },
  {path: '/street-pwd-change', name: 'street-pwd-change', component: () => import('../views/streetMy/pwd-change.vue') },
  {path: '/street-mobile-change', name: 'street-mobile-change', component: () => import('../views/streetMy/mobile-change.vue') },
  {path: '/all-preview', name: 'all-preview', component: () => import('../views/common/all-preview.vue'),meta:{title: '街道数据总览'} },
  //管理员总览
  {path: '/adminCode', name: 'adminCode', component: () => import('../views/adminCode/AdminCode.vue'),meta:{title: '总览',fromRefresh: 1} },
  //管理员总览
  {path: '/userReal', name: 'userReal', component: () => import('../views/adminCode/userReal.vue'),meta:{title: '总览',fromRefresh: 1} },
  {path: '/404', name: '404', component: () => import('../views/common/404.vue'), meta:{title: '404'}},
  {path: '/*', name: '404', redirect:'/404'},

]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前
  if(to.meta.title){//判断是否有标题
    document.title = to.meta.title
  }
  let quitList = [
    {from: '/login', to: ['/white']},
    {from: '/home', to: ['/login', '/white']},
    {from: '/white', to: ['/', '/my']},
    {from: '/pwd-change', to: ['/login']},
  ]
  let flag = quitList.some(item => from.path === item.from && item.to.indexOf(to.path) !== -1)
  if (dd && flag) {
      dd.closePage()
    }
    if (window.__wxjs_environment === 'miniprogram' && to.path === '/sso' && from.path !== '/') {
      wx.miniProgram.postMessage({data: 'close'})
      WeixinJSBridge.call('closeWindow')
      wx.miniProgram.navigateBack()
    }
  next()
})

export default router

